import Image from 'next/image'
import Link from 'next/link'
import { useEffect, useState } from 'react'
import { Fade } from 'react-awesome-reveal'

import styles from './value-score.module.scss'
import { Tooltip } from '@/components/atomic/Tooltip'
import { LocoScore } from '@/components/styleguide/LocoScore'
import { VehicleImage } from '@/components/styleguide/VehicleImage'
import { constants, FILTER_VEHICLE_TYPES_VANS } from '@/lib/constants'
import { ROUTES } from '@/lib/routes'
import { getUrlReadyString } from '@/lib/utilities/vehicle'

const ValueScore = ({ deal, isVans }) => {
  const [url, setUrl] = useState('')

  useEffect(() => {
    if (deal) {
      const profile = `${deal.vehiclePrice.leaseType}-${deal.vehiclePrice.term}-${deal.vehiclePrice.mileage}-${deal.vehiclePrice.initialPaymentInMonths}-${deal.vehiclePrice.stockStatus}`
      const newUrl = `/${
        deal.vehiclePrice.vehicleType === FILTER_VEHICLE_TYPES_VANS ? 'van' : 'car'
      }-leasing/${getUrlReadyString(deal.vehiclePrice.manufacturerName)}/${getUrlReadyString(
        deal.vehiclePrice.rangeName,
      )}/${getUrlReadyString(deal.vehiclePrice.derivativeName)}/${
        deal.vehiclePrice.vehicleId
      }/${profile}/${deal.vehiclePrice.id}/config`

      setUrl(newUrl)
    }
  }, [deal])

  return (
    <section className={styles.container}>
      <div className={styles.confetti}></div>
      <div className={styles.grid}>
        <div className={styles.column}>
          <h2 className={styles.heading}>
            The higher the LocoScore,
            <br />
            the better the lease deal.
          </h2>
        </div>
        <div className={styles.column}>
          <div className={styles.text}>
            <p>
              <span>Looking for a great price? How about great value?</span> Our unique LocoScore
              algorithm rates every {isVans ? 'van' : 'car'} lease deal out of 100, giving you the
              best value and letting you know what&apos;s hot and what&apos;s . . . not.
            </p>
            {isVans ? (
              <p>
                We&apos;ll help find the right van or{' '}
                <Link href={ROUTES.homePage.url}>
                  <a>car lease deals</a>
                </Link>{' '}
                for you. Deals in the 60-69 territory can be considered average, whilst anything
                from 70 upwards is good value. And 100? Well, you&apos;re dreaming.
              </p>
            ) : (
              <p>
                We&apos;ll help find the right{' '}
                <Link href={ROUTES.vanHomePage.url}>
                  <a>van</a>
                </Link>{' '}
                or{' '}
                <Link href={'/car-leasing/search'}>
                  <a>car lease deals</a>
                </Link>{' '}
                for you. Deals in the 60-69 territory can be considered average, whilst anything
                from 70 upwards is good value. And 100? Well, you&apos;re dreaming.
              </p>
            )}
          </div>
        </div>
      </div>
      <div className={`${styles.grid} ${styles['has-no-mobile-margin']}`}>
        <div className={styles.column}>
          <div className={styles['data-points']}>
            <div className={styles.data}>
              <p>
                Value at
                <br />a glance
              </p>
              <p>Easily see the best value</p>
            </div>
            <div className={styles.data}>
              <p>
                Dynamic
                <br />
                scoring
              </p>
              <p>Tailored to your terms</p>
            </div>
            <div className={`${styles.data} ${styles['desktop-margin-top']}`}>
              <p>
                Real-time
                <br />
                comparison
              </p>
              <p>Based on all available deals</p>
            </div>
            <div className={`${styles.data} ${styles['desktop-margin-top']}`}>
              <p>
                Lease with
                <br />
                confidence
              </p>
              <p>With our LocoScore</p>
            </div>
          </div>
        </div>
        <div className={`${styles.column} ${styles['is-visible']}`}>
          <div className={styles.vehicle}>
            {deal && (
              <>
                <div className={styles['vehicle-data']}>
                  <Tooltip
                    id="loco-score-helper"
                    icon={
                      <LocoScore
                        className={styles.score}
                        rating={deal.vehiclePrice.rating}
                        iconOnly
                      />
                    }
                    rating={deal.vehiclePrice.rating}
                    title="LocoScore"
                    iconSize={16}>
                    <p>
                      Powered by our lease comparison algorithm this score indicates how good a deal
                      really is.{' '}
                      <Link
                        href={ROUTES.locoScorePage.url}
                        passHref>
                        <a target="_blank">
                          Learn more<span className="sr-only"> about the Loco Score</span>
                        </a>
                      </Link>
                    </p>
                  </Tooltip>
                  <p className={styles['vehicle-name']}>
                    {deal.vehiclePrice.manufacturerName} {deal.vehiclePrice.rangeName}
                  </p>
                  <p className={styles['vehicle-derivative']}>{deal.vehiclePrice.derivativeName}</p>
                  <Link href={url}>
                    <a>
                      View vehicle{' '}
                      <Image
                        src={`${constants.imageHost.assets}/icons/library/chevron-right.svg`}
                        height="16"
                        width="16"
                        alt="chevron right icon"
                      />
                    </a>
                  </Link>
                </div>
                <div className={styles['vehicle-image']}>
                  <Fade triggerOnce>
                    <VehicleImage
                      imagePath={
                        deal.vehicle.gallery?.images?.filter(img => img.angle === 'sideview')[0]
                          .path
                      }
                      backupImagePath={deal.vehicle.images.side}
                      alt={`${deal.vehicle.manufacturerName} ${deal.vehicle.modelName}`}
                      height={630}
                      width={945}
                      type="HOMEPAGE"
                    />
                  </Fade>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export default ValueScore
