import NiceModal from '@ebay/nice-modal-react'
import dynamic from 'next/dynamic'
import Image from 'next/image'
import { useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import Popup from 'reactjs-popup'

import styles from './tooltip.module.scss'
import { LocoScore } from '@/components/styleguide/LocoScore'
import { constants } from '@/lib/constants'
import { addToDataLayer } from '@/lib/utilities/vehicle'

const TooltipModal = dynamic(() => import('./TooltipModal'), { ssr: false })

const popoverStyle = {
  border: 0,
  boxShadow: '0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08)',
  borderRadius: 24,
  padding: 24,
  zIndex: 99999,
  textAlign: 'left',
  display: 'flex',
  flexDirection: 'column',
  gap: '0.75rem',
}

const popupEvents = ['hover', 'click', 'focus']
const popupPositions = ['top center', 'bottom center', 'right center', 'left center']

export const Tooltip = ({
  id,
  children,
  title,
  label,
  position = popupPositions,
  width = 300,
  iconSize = 16,
  icon,
  rating,
  onOpenModal,
  onCloseModal,
}) => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false)
  const isMobile = useMediaQuery({ query: '(max-width: 991px)' })

  const onOpenTooltipModal = () => {
    if (onOpenModal) {
      onOpenModal()
    }
    NiceModal.show(TooltipModal, { id, children, title, icon, onCloseModal })
  }

  if (isMobile) {
    return (
      <button onClick={onOpenTooltipModal}>
        {icon ? (
          icon
        ) : (
          <>
            {!!label && <span onClick={e => e.preventDefault()}>{label}</span>}
            <Image
              src={`${constants.imageHost.assets}/icons/library/info.svg`}
              alt=""
              height={16}
              width={16}
            />
          </>
        )}
      </button>
    )
  }

  return (
    <Popup
      trigger={
        <button
          type="button"
          className={styles.button}
          onClick={e => {
            e.stopPropagation()
            e.preventDefault()
            addToDataLayer({
              event: `tooltip-${id}`,
              value: !isTooltipOpen,
            })
            setIsTooltipOpen(!isTooltipOpen)
          }}>
          {icon ? (
            icon
          ) : (
            <>
              {!!label && <span onClick={e => e.preventDefault()}>{label}</span>}
              <Image
                src={`${constants.imageHost.assets}/icons/library/info.svg`}
                height={iconSize}
                width={iconSize}
                alt=""
                onClick={e => e.preventDefault()}
              />
            </>
          )}
        </button>
      }
      open={isTooltipOpen}
      arrow={false}
      position={position}
      contentStyle={{
        ...popoverStyle,
        width,
      }}
      overlayStyle={{
        zIndex: 25,
      }}
      offsetY={8}
      offsetX={8}
      closeOnDocumentClick
      on={popupEvents}
      keepTooltipInside>
      <div className={styles.icon}>
        {rating ? (
          <LocoScore
            rating={rating}
            iconOnly
          />
        ) : (
          <Image
            src={`${constants.imageHost.assets}/icons/library/info.svg`}
            height={36}
            width={36}
            className={styles['help-icon']}
            alt=""
            onClick={e => e.preventDefault()}
          />
        )}
      </div>
      <div className={styles.header}>{title}</div>
      <div className={styles.content}>{children}</div>
    </Popup>
  )
}
